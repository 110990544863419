<template>
  <div
      v-click-outside="() => show = false"
      :class="{ search_header: header }" class="search"
  >
    <div
        class="form_field"
    >
      <input
          :id="uniqueId"
          ref="search_elem"
          v-model="search"
          :placeholder="`${$t('search_item')}...`"
          autocomplete="off"
          class="form_input with-icon"
          type="text"
          @focus="onFocus"
          @input="sendGtag"
          @keyup.enter="setSearch"
          @keydown.up.prevent="onArrowUp"
          @keydown.down.prevent="onArrowDown"
          @keydown.enter.prevent="onEnter"
      />
      <label :for="uniqueId">
        <span class="icon f fc wh43">
          <i v-if="loading" class="i-loading" />
          <i v-else class="icon i-search f18 wh43" />
        </span>
      </label>
      <button
          v-if="showClear"
          :class="{header}"
          aria-label="Clear"
          class="btn wh43 btn_clear"
          @click="clearSearch()"
      >
        <i class="i-times f14"></i>
      </button>
      <button
          v-if="header"
          aria-label="Advanced search"
          class="btn btn-default wh43 btn_advanced"
          @click="openAdvancedSearchModal()"
      >
        <i class="i-filter f20"></i>
      </button>
    </div>
    <div v-show="show" :class="{ open: show }" class="search_wrap">
      <ul class="list search_list">
        <li
            v-for="(item, i) of items"
            :key="i"
            :class="{ 'is-selected': selectedIndex === i }"
            class="item"
        >
          <button
              :ref="el => { refs[item.market_hash_name] = el }"
              class="btn btn-primary fg tal"
              @click="setItem($event, item)">
            <span
                :style="item.rarity_color ? `color: ${item.rarity_color};` : null"
                class="thumb"
            >
              <img
                  :alt="item.market_hash_name"
                  :src="getImageFromWaxpeer(item.market_hash_name, '50:50')"
                  class="wh40"
                  loading="lazy"
              />
            </span>
            <span class="name fg">
              {{ item.localed_name }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>


<script lang="ts" setup>
import {ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useResize} from '~/composables/resize.js'
import EventBus from '~/composables/bus'
import {useI18n} from 'vue-i18n'
import {simpleSearch} from '~/utils/api.js'
import {getImageFromWaxpeer} from "~/utils/get-image-url";
import {getItemLinkWithExterior} from "~/utils/name-utils";
import type {SimpleSearch} from "~/graphql/generated/schema-types";

const uniqueId = useId();
const currW = useResize()

const {t, locale} = useI18n()
const {gtag} = useGtag()

const props = defineProps<{
  header: boolean
}>()

const route = useRoute()
const router = useRouter()
const debounce = useDebounce()

const search_elem = ref<HTMLInputElement>()
const show = ref(false)
const items = ref<SimpleSearch[]>([])
const search = ref<string | null>(null)
const loading = ref(false)
const showClear = ref(false)
const selectedIndex = ref(-1)
const refs = ref({})

const onFocus = () => {
  show.value = !!items.value.length
  selectedIndex.value = 0
}

const setItem = (event: Event, {market_hash_name, link}: SimpleSearch) => {
  if (!props.header) {
    event.preventDefault()
    search.value = market_hash_name
    EventBus.$emit('comp_search', market_hash_name)

    nextTick().then(() => {
      show.value = false
    })

    if (search_elem.value) {
      search_elem.value.blur()
    }
  } else {
    search.value = null
    items.value = []
    router.push(getItemLinkWithExterior({name: market_hash_name, slug: link || ''}))
    showClear.value = false
  }
  selectedIndex.value = 0
  show.value = false
}


const fetchItems = async () => {
  if (search.value && search.value.length >= 3) {
    loading.value = true

    try {
      let {errors, data} = await simpleSearch(search.value)

      if (errors?.length) {
        throw new Error(errors[0].message)
      }
      items.value = data.simpleSearch ? [...data.simpleSearch] : []
    } catch (e) {
      console.log('simpleSearch() error', (e as Error).message)
      EventBus.$emit('notify', {
        success: false,
        msg: (e as Error).message || 'An error occurred'
      })
    } finally {
      loading.value = false;
    }
  }
}

const debouncedFetchItems = debounce(fetchItems, 500);

watch(search, async (val) => {
  if (!val) {
    showClear.value = false
    show.value = false
    selectedIndex.value = 0
  }
  if (val && val.length >= 3) {
    debouncedFetchItems()
    showClear.value = true

    if (items.value) {
      show.value = true
    }
  }
})


const setSearch = () => {
  if (!props.header) {
    EventBus.$emit('comp_search', search.value)
    show.value = false

    if (props.header && search_elem.value) {
      search_elem.value.blur()
    }
  }
}

const clearSearch = () => {
  search.value = null
  show.value = false
  items.value = []
  showClear.value = false
  selectedIndex.value = 0

  if (!props.header) {
    EventBus.$emit('comp_search', search.value)
  }
}

const openAdvancedSearchModal = () => {
  EventBus.$emit('change_modal', {name: 'adv_search', value: true})
}

// onMounted(() => {
//   if (route.query && route.query.search) {
//     setItem({} as Event, route.query.search.toString())
//
//     nextTick(() => {
//       show.value = false
//     })
//   }
// })


const onArrowUp = () => {
  if (selectedIndex.value > 0) {
    selectedIndex.value--
  } else {
    selectedIndex.value = items.value.length - 1
  }
}

const onArrowDown = () => {
  if (selectedIndex.value < items.value.length - 1) {
    selectedIndex.value++
  } else {
    selectedIndex.value = 0
  }
}

const onEnter = () => {
  if (selectedIndex.value >= 0 && selectedIndex.value < items.value.length) {
    // @ts-ignore
    refs.value[items.value[selectedIndex.value].market_hash_name]?.click()
  }
}

const sendGtag = debounce(() => {
  if (!search.value) return

  gtag('event', 'csmarket-search', {
    search_term: search.value,
    event_label: 'Main search component(header, comparison...)'
  })
}, 1000)
</script>
<style lang="scss" scoped>
@use '/assets/scss/mixins.scss' as *;
@use 'sass:color';

.search {
  position: relative;


  input {
    padding-right: 3.8rem;
    width: 23rem;
  }

  &.search_header input {
    width: 25rem;
    padding-left: 3.8rem;
    padding-right: 7.6rem;
  }

  &_wrap {
    position: absolute;
    top: 110%;
    z-index: 10;
  }

  &_list {
    background: $dark;
    border-radius: 0.6rem;
    max-height: 50.5rem;
    overflow-y: auto;

    li {
      &:first-child {
        &:before {
          content: '';
          display: block;
          padding-bottom: 0.5rem;
        }
      }

      &:last-child {
        &:after {
          content: '';
          display: block;
          padding-bottom: 0.5rem;
        }
      }

      &.is-selected {
        background: color.adjust($darkBtn, $lightness: 5%);
      }
    }

    .btn-primary {
      background: transparent;
      min-height: 5rem;
      height: auto;
      font-size: 1.2rem;
      border-radius: 0;
      padding: 0 1rem;
      color: color.adjust($white, $alpha: -0.5);
      width: 100%;

      &:hover,
      &:focus {
        background: color.adjust($darkBtn, $lightness: 5%);
        color: $white;
      }
    }

    .thumb {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient(
                circle,
                currentColor 0%,
                rgba(0, 0, 0, 0) 60%
        );
        opacity: 0.15;
      }

      img {
        position: relative;
      }
    }
  }
}

.btn-default {
  position: absolute;
  top: 0;
  right: 0;
}

.btn_clear {
  position: absolute;
  top: 0;
  right: 0;

  &.header {
    right: 40px;
  }
}

@media screen and (max-width: 979px) {
  .search_header {
    .form_field {
      input {
        height: 4.5rem;
      }

      .icon,
      .btn {
        height: 4.5rem;
      }
    }

    .search_wrap {
      left: 0;
    }
  }
  .profile-nav_bottom__wrap {
    .search_header {
      .form_field {
        input {
          height: 5.6rem;
        }

        .icon,
        .btn {
          height: 5.6rem;
        }
      }

      .search_wrap {
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 829px) {
  .search.search_header {
    .form_input {
      width: 100%;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 768px) {
  .search {
    flex-grow: 1;

    input {
      width: 100%;
      max-width: initial;
    }

    &_wrap {
      left: -5rem;
      right: 0;
    }

    &_list {
      max-height: 20rem;
    }
  }
}
</style>
